<template>
  <main>
  <Navigation />
    <div class="chi-sono" >
      <MainText :text="content.intro" />  
      <TwoParagraphsText :class="'chi-sono__services'" :text="content.services" />
      <Carousel :images="content.images" />
      <TwoParagraphsText :text="content.description" />
      <Footer />
    </div>
  </main>
</template>

<script>

import Carousel from '../../components/Carousel/Carousel.vue'
import Navigation from './../../components/Navigation/Navigation.vue'
import Footer from './../../components/Footer/Footer.vue'
import MainText from './../../components/MainText/MainText.vue'
import TwoParagraphsText from './../../components/TwoParagraphsText/TwoParagraphsText.vue'
import seoJson from './../../utils/seoJson.js'

export default {
  name: 'Servizi',
  components: {
    Carousel,
    Navigation,
    Footer,
    MainText,
    TwoParagraphsText
  },
  data: () => ({
    content: require("./data.json")
  }),
  props: {},
  metaInfo: {
    title: 'Lo studio di architettura',
    meta: [
      { name: 'description', content: "Le referenze dell'architetto Alessandro Moretto, lo studio e la sua mission: dare forma e sostanza alle esigenze abitative e lavorative delle persone."}
    ],
    links: [
      {rel: 'canonical', href: 'https://www.alessandromoretto.it/studio'}
    ]
    // meta:  seoJson('Lo studio di architettura e l\'architetto Alessandro Moretto','https://www.alessandromoretto.it/studio','Le referenze dell\'architetto Alessandro Moretto, lo studio e la sua mission: dare forma e sostanza alle esigenze abitative e lavorative delle persone.', '/assets/img/social/studio-alessandro-moretto.jpg')
  }
}
</script>

<style lang="scss">

@import './style.scss';

</style>