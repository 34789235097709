<template>
  <div id="app">
    <MousePointer />
    <cookie-law>
      <div slot="message">
        Here is my message for more info <router-link to="legal-notes">Click here</router-link>
      </div>
    </cookie-law>
    <div class="site-wrap">
      <transition name="fade"  mode="out-in" appear
      v-on:before-enter="beforeEnter">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import MousePointer from './components/MousePointer/MousePointer.vue'
import CookieLaw from './components/CookieLaw/CookieLaw.vue'

export default {
  components: {
    MousePointer,
    CookieLaw
  },
  methods: {
    beforeEnter: function (el) {
      window.scrollTo(0,0)
    }
  },
  metaInfo: {
    title: 'Alessandro Moretto',
    titleTemplate: '%s | Alessandro Moretto',
    htmlAttrs: {
      lang: 'it',
    },
    links: [
      {rel: 'canonical', href: 'https://www.alessandromoretto.it/'},
    ]
  }
}

</script>

<style lang="scss">

@import './app.scss';

</style>
  