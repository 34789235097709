<template>
  <div class="mouse-custom" v-if="!touchDevice" v-mouse-pointer v-mouse-hover>
    <div class="mouse-custom__pointer"
    :class="[{'mouse-custom__pointer--inLink': activeInLink}, {'mouse-custom__pointer--inProject': activeInProject}]">
      <span>Scopri</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { styler, spring, listen, value } from 'popmotion'

export default {
  name: 'MousePointer',
  data: () => ({
    activeInLink: false,
    activeInProject: false,
    touchDevice: false
  }),
  computed: {
    ...mapGetters([
      'mouseCursor'
    ])
  },
  mounted () {
    window.addEventListener('touchstart', () => { this.touchDevice = true })
  },
  directives: {
    'mouse-hover':{
      bind (el, binding, vnode){
   
        document.addEventListener('mouseover', (e) => {
          
          if (!event.target.closest('a') && !event.target.closest('button') && !event.target.closest('.Cookie__button')) return;
          vnode.context.activeInLink = true
          vnode.context.activeInProject = vnode.context.mouseCursor !== 'base' ? true : false

        })

        document.addEventListener('mouseover', (e) => {
          
          if (!event.target.closest('a') && !event.target.closest('button') && !event.target.closest('.Cookie__button')){
          
            vnode.context.activeInLink = false
            vnode.context.activeInProject = false
            
          }

        })

      }
    },
    'mouse-pointer': {
      bind (el) {
        const divStyler = styler(el)
        const mouseXY = value({ x: 0, y: 0 }, divStyler.set)
        listen(document, 'mousemove mousewheel')
          .start((e) => {
            spring({
              from: mouseXY.get(),
              velocity: mouseXY.getVelocity(),
              to: { x: e.clientX + window.pageXOffset, y: e.clientY + window.pageYOffset},
              stiffness: 600,
              mass: 1,
              damping: 300
            }).start(mouseXY)
          })
      }
    }
  }
}

</script>

<style lang="scss">

@import './style.scss';

</style>

