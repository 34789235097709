import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    mouseCursor: 'base'
  },
  getters: {
    mouseCursor: state => state.mouseCursor
  },
  mutations: {
    updateMouseCursor (state, type) {
      state.mouseCursor = type
    }
  }
})