<template>
  <main>
    <Navigation />
    <div class="home" >
      <Carousel :images="content.images" />
      <MainText :text="content.intro" />    
      <Footer />
    </div>
  </main>
</template>

<script>

import Carousel from '../../components/Carousel/Carousel.vue'
import Navigation from './../../components/Navigation/Navigation.vue'
import Footer from './../../components/Footer/Footer.vue'
import MainText from './../../components/MainText/MainText.vue'
import seoJson from './../../utils/seoJson.js'

export default {
  name: 'home',
  components: {
    Carousel,
    Navigation,
    Footer,
    MainText
  },
  data: () => ({
    content: require("./data.json")
  }),
  metaInfo: {
    title: 'Alessandro Moretto architetto Feltre, Belluno',
    titleTemplate: '%s',
    meta: [
      {
        name: 'description', 
        content: 'Studio di architettura e consulenza per progettazione, ristrutturazioni e restauri.'}
    ],
    links: [
      {rel: 'canonical', href: 'https://www.alessandromoretto.it/'}
    ]
    // meta:  seoJson('Alessandro Moretto architetto a Feltre, Belluno','https://www.alessandromoretto.it/','Studio di architettura, progettazione, ristrutturazione e restauro a Feltre, Belluno.', '/assets/img/social/architetto-alessandro-moretto.jpg')
  }
}
</script>

<style lang="scss">

@import './style.scss';

</style>