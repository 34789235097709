<template>
  <div class="carousel">
    <tiny-slider :mode="'gallery'" :autoplay="true" :autoplayTimeout="3500" :nav="true" :controls="false" :speed="1000" :autoplayButtonOutput="false">
      <div v-for="(image, index) in images" v-bind:key="`image-${index}`">
        <picture>
          <source
            :data-srcset="image.srcSmall"
            media="--small" />
          <source
            :data-srcset="image.srcLarge"
            media="--large" />
          <img
              src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
              :data-src="image.src"
              :alt="image.alt"
              class="lazyload"
              data-sizes="auto" />
        </picture>
      </div>
    </tiny-slider>
  </div>
</template>

<script>

import VueTinySlider from 'vue-tiny-slider';

export default{
  components: {
    'tiny-slider': VueTinySlider
  },
  props: {
    images: {
      type: Array,
      required: true
    }
  },
  mounted () {
  }
}

</script>

<style lang="scss">

@import './style.scss';

</style>