<template>
  <main>
    <Navigation />
    <div class="ricerca">
      <header class="ricerca__header">
        <h1 v-html="content.titoloHtml" />
      </header>
      <div class="ricerca__intro">
        <TwoParagraphsText :text="content.description" />
      </div>
      
      <BackButton :isVisible="this.listInView" />
      <div class="lavoro__content__anchor" v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }" />
      
      <div class="ricerca__chapter" v-for="(ricerca, index) in content.ricerche" :key="index">
        <div class="ricerca__chapter__header">
          <h2>{{ricerca.titoloRicerca}}</h2>
        </div>
        <TwoColText :text="ricerca.descriptionRicerca" />
        <Carousel :images="ricerca.gallery" />
      </div>

      <div class="lavoro__content__anchor" v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }" />

      <Footer />
    </div>
  </main>
</template>

<script>

import Navigation from './../../components/Navigation/Navigation.vue'
import Footer from './../../components/Footer/Footer.vue'
import Carousel from '../../components/Carousel/Carousel.vue'
import TwoParagraphsText from './../../components/TwoParagraphsText/TwoParagraphsText.vue'
import TwoColText from './../../components/TwoColText/TwoColText.vue'
import BackButton from './../../components/BackButton/BackButton.vue'
import seoJson from './../../utils/seoJson.js'

export default {
  name: 'ricerca',
  components: {
    Navigation,
    Footer,
    TwoParagraphsText,
    Carousel,
    TwoColText,
    BackButton
  },
  data: () => ({
    content: require("./data.json"),
    listInView: false,
    intersectionOptions: {
      threshold: 0
    }
  }),
  methods: {
    onWaypoint ({el, going, direction }) {

      if (going === this.$waypointMap.GOING_IN && direction === this.$waypointMap.DIRECTION_TOP) {
        this.listInView = !this.listInView 
      }
      else if(going === this.$waypointMap.GOING_OUT && direction === this.$waypointMap.DIRECTION_BOTTOM) {
        this.listInView = !this.listInView 
      }
      
    }
  },
  metaInfo: {
    title: "Ricerche sull\'architettura rurale",
    meta: [
      { name: 'description', content: "Studi e ricerche sull'architettura rurale e il paesaggio in collaborazione con l'associazione Isoipse di Belluno." }
    ],
    links: [
      {rel: 'canonical', href: 'https://www.alessandromoretto.it/ricerche'}
    ]
    // meta:  seoJson('Ricerche sull\'architettura rurale','https://www.alessandromoretto.it/ricerche','Studi e ricerche sull\'architettura rurale e il paesaggio in collaborazione con l\'associazione Isoipse di Belluno.', '/assets/img/social/ricerche-alessandro-moretto.jpg')
  }
}

</script>

<style lang="scss">

@import './style.scss';

</style>