<template>

  <footer>
    <p>Arch. Alessandro Moretto<br/> Porta Imperiale 5, Feltre (BL)<br/> <small>P.iva 01047490253</small></p>
    <p><a :href="'mailto:studio@alessandromoretto.it'">studio@alessandromoretto.it</a><br/> <a :href="'tel:+39043981364'">Tel. +39043981364</a><br/> <a :href="'tel:+393206720870'">Cell. +393206720870</a></p>
    <p>
      <a :href="'https://www.facebook.com/alessandromoretto13'" target="_blank">
        <svg width="11px" height="20px" viewBox="0 0 11 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="facebook" fill="#000000" fill-rule="nonzero">
            <path d="M10.3276289,0.00412371134 L7.75752577,0 C4.87010309,0 3.00412371,1.91443299 3.00412371,4.87752577 L3.00412371,7.12639175 L0.42,7.12639175 C0.196701031,7.12639175 0.0158762887,7.30742268 0.0158762887,7.53072165 L0.0158762887,10.7890722 C0.0158762887,11.0123711 0.196907216,11.1931959 0.42,11.1931959 L3.00412371,11.1931959 L3.00412371,19.4150515 C3.00412371,19.6383505 3.18494845,19.8191753 3.40824742,19.8191753 L6.77979381,19.8191753 C7.00309278,19.8191753 7.18391753,19.6381443 7.18391753,19.4150515 L7.18391753,11.1931959 L10.2053608,11.1931959 C10.4286598,11.1931959 10.6094845,11.0123711 10.6094845,10.7890722 L10.6107216,7.53072165 C10.6107216,7.42350515 10.5680412,7.32082474 10.4923711,7.24494845 C10.416701,7.16907216 10.3136082,7.12639175 10.2063918,7.12639175 L7.18391753,7.12639175 L7.18391753,5.22 C7.18391753,4.30371134 7.40226804,3.8385567 8.59587629,3.8385567 L10.3272165,3.83793814 C10.5503093,3.83793814 10.731134,3.65690722 10.731134,3.43381443 L10.731134,0.408247423 C10.731134,0.185360825 10.5505155,0.00453608247 10.3276289,0.00412371134 Z" id="Shape"></path>
          </g>
        </svg>
      </a>
      <a :href="'https://www.instagram.com/alesandro_moretto/'" target="_blank">
        <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="instagram" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="noun_Camera_1841840" fill="#000000" fill-rule="nonzero">
                  <path d="M4.70588235,0 C2.09884118,0 0,2.09885232 0,4.70588097 L0,15.294119 C0,17.9011477 2.09884118,20 4.70588235,20 L15.2941176,20 C17.9011588,20 20,17.9011477 20,15.294119 L20,4.70588097 C20,2.09885232 17.9011588,3.34372954e-14 15.2941176,3.34372954e-14 L4.70588235,3.34372954e-14 Z M15.8823529,2.94117647 C16.5320882,2.94117647 17.0588235,3.46788235 17.0588235,4.11764706 C17.0588235,4.76738235 16.5320882,5.29412059 15.8823529,5.29412059 C15.2325882,5.29412059 14.7058824,4.76738235 14.7058824,4.11764706 C14.7058824,3.46788235 15.2325882,2.94117647 15.8823529,2.94117647 Z M10,5 C12.7544676,5 15,7.24556176 15,10.0000059 C15,12.7544471 12.7544676,15.0000059 10,15.0000059 C7.24553235,15.0000059 5,12.7544471 5,10.0000059 C5,7.24556176 7.24553235,5 10,5 Z M10,6.17647353 C7.88135,6.17647353 6.17647059,7.88138529 6.17647059,10.0000059 C6.17647059,12.1186235 7.88135,13.8235353 10,13.8235353 C12.11865,13.8235353 13.8235294,12.1186235 13.8235294,10.0000059 C13.8235294,7.88138529 12.11865,6.17647353 10,6.17647353 Z" id="Shape"></path>
              </g>
          </g>
      </svg>
      </a>
      <a :href="'https://it.linkedin.com/in/alessandro-moretto-82415464'" target="_blank">
        <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="linkedin" fill="#000000" fill-rule="nonzero">
            <path d="M4.65548387,6.43419355 L0.640451613,6.43419355 C0.462258065,6.43419355 0.317870968,6.57864516 0.317870968,6.75677419 L0.317870968,19.6553548 C0.317870968,19.8335484 0.462258065,19.9779355 0.640451613,19.9779355 L4.65548387,19.9779355 C4.83367742,19.9779355 4.97806452,19.8335484 4.97806452,19.6553548 L4.97806452,6.75677419 C4.97806452,6.57864516 4.83367742,6.43419355 4.65548387,6.43419355 Z" id="XMLID_802_"></path>
            <path d="M2.64941935,0.022 C1.18851613,0.022 0,1.20922581 0,2.66851613 C0,4.12845161 1.18851613,5.31612903 2.64941935,5.31612903 C4.10916129,5.31612903 5.29670968,4.1283871 5.29670968,2.66851613 C5.29677419,1.20922581 4.10916129,0.022 2.64941935,0.022 Z" id="XMLID_803_"></path>
            <path d="M14.868,6.1136129 C13.2554194,6.1136129 12.0633548,6.80683871 11.3403226,7.59451613 L11.3403226,6.75677419 C11.3403226,6.57864516 11.1959355,6.43419355 11.0177419,6.43419355 L7.17264516,6.43419355 C6.99445161,6.43419355 6.85006452,6.57864516 6.85006452,6.75677419 L6.85006452,19.6553548 C6.85006452,19.8335484 6.99445161,19.9779355 7.17264516,19.9779355 L11.1789032,19.9779355 C11.3570968,19.9779355 11.5014839,19.8335484 11.5014839,19.6553548 L11.5014839,13.2735484 C11.5014839,11.1230323 12.0856129,10.2852258 13.5847097,10.2852258 C15.2173548,10.2852258 15.3470968,11.6283226 15.3470968,13.3841935 L15.3470968,19.6554194 C15.3470968,19.8336129 15.4914839,19.978 15.6696774,19.978 L19.6774194,19.978 C19.8556129,19.978 20,19.8336129 20,19.6554194 L20,12.5803226 C20,9.38258065 19.3902581,6.1136129 14.868,6.1136129 Z" id="XMLID_804_"></path>
          </g>
        </svg>
      </a>
    </p>
    <div :class="'footer__privacy'">
      <a href="https://www.iubenda.com/privacy-policy/98532547" class="iubenda-white iubenda-embed " title="Cookie Policy">Cookie Policy</a> <span>|</span> <a href="/assets/doc/Informativa-Privacy-Clienti.pdf" class="footer__privacy__label" target="_blank">Informativa sulla Privacy</a> 
    </div>
  </footer>

</template>

<script>

export default {
  name: 'Footer',
  mounted () {
    var s = document.createElement("script"), tag = document.getElementsByTagName("script")[0]; 
    s.src="https://cdn.iubenda.com/iubenda.js"; 
    tag.parentNode.insertBefore(s,tag);
  }
}

</script>

<style lang="scss">

@import './style.scss';

</style>

