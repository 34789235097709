<template>
  <transition name="fade">
    <button v-if="this.isVisible" @click="scrollToTop" class="back-button"></button>
  </transition>
</template>

<script>
import { styler, tween } from 'popmotion'

export default {
  name: 'BackButton',
  props: {
    isVisible: {
      type: false
    }
  },
  methods: {
    scrollToTop: () =>{
      /*window.scrollTo({
        top: 0,
        behavior: "smooth"
      });*/

      const viewportStyler = styler(window);
      tween({
        from: { 'scrollTop': viewportStyler.get('scrollTop') },
        to: { 'scrollTop': 0 },
        duration: 1200
      }).start(viewportStyler.set)
      
    }
  }
}

</script>

<style lang="scss">

@import './style.scss';

</style>

