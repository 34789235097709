import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import store from './store/index.js'

import VueWaypoint from 'vue-waypoint'

import respimg from 'lazysizes/plugins/respimg/ls.respimg'
import custommedia from 'lazysizes/plugins/custommedia/ls.custommedia'
import lazySizes from 'lazysizes'

/** Waypoint */
Vue.use(VueWaypoint)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

/** Lazysizes Config */
window.lazySizesConfig = window.lazySizesConfig || {}

window.lazySizesConfig.customMedia = {
    '--small': '(max-width: 749px)',
    '--large': '(max-width: 1000)'
}
window.lazySizesConfig.loadMode = 1
lazySizesConfig.preloadAfterLoad = true
