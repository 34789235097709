<template>

  <nav>
    <router-link :to="'/'"><img src="./../../assets/alessandro-moretto-logo.svg" width="326" height="51" alt="Alessandro Moretto Architetto" /></router-link>
    <ul>
      <li>
        <router-link :to="{ name: 'lavori' }">Lavori</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'ricerche' }">Ricerche</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'servizi' }">Servizi</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'contatti' }">Contatti</router-link>
      </li>
    </ul>
  </nav>

</template>

<script>

export default {
  name: 'Navigation',
  computed: {},
  methods: {},
  mounted() {},
  beforeDestroy () {}
}

</script>

<style lang="scss">

@import './style.scss';

</style>
