<template>
  <div         
    :class="'reveal-image'">
    <div v-if="this.isPassed" class="reveal-image__waypoint" v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }"></div>
    <slot></slot>
  </div>
</template>

<script>

import { styler, value, tween } from 'popmotion'

export default {
  name: 'RevealBlock',
  data: () => ({
    intersectionOptions: {
      threshold: 0.25
    },
    isPassed: {
      type: Boolean,
      default: false
    }
  }),
  methods: {
    onWaypoint ({el, going, direction }) {
      if (going === this.$waypointMap.GOING_IN) {
        this.isPassed = true;
      }
    }
  },
  watch: {
    isPassed: function(){

      const revealImg = styler(this.$el)
      tween({ to: 1, duration: 1000 }).start(
        {
        update: (v) => value(v, revealImg.set('opacity')),
        }       
      )

    }
  }
}

</script>

<style lang="scss">

@import './style.scss';

</style>