<template>
  <cookie-law theme="banner" buttonText="Accetto" transitionName="fade" message="Questo sito utilizza cookies a fini statistici e per migliorare l’esperienza utente."></cookie-law>
</template>
 
<script>
  import CookieLaw from 'vue-cookie-law'
  export default {
    components: { 
      CookieLaw 
    }
  }
</script>
<style lang="scss">

@import './style.scss';

</style>