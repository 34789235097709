import Vue from 'vue'
import Router from 'vue-router'
import VueAnalytics from 'vue-analytics'

import Meta from 'vue-meta'
import Home from '../views/Home/Home.vue'
import Lavori from '../views/Lavori/Lavori.vue'
import Lavoro from '../views/Lavoro/Lavoro.vue'
import Ricerche from '../views/Ricerche/Ricerche.vue'
import Servizi from '../views/Servizi/Servizi.vue'
import Contatti from '../views/Contatti/Contatti.vue'
import NotFound from '../views/NotFound/NotFound.vue'

Vue.use(Router)
Vue.use(Meta)


const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/lavori/',
      name: 'lavori',
      component: Lavori,
    },
    {
      path: '/lavori/:lavoro',
      name: 'lavoro',
      component: Lavoro,
    },
    {
      path: '/ricerche',
      name: 'ricerche',
      component: Ricerche
    },
    {
      path: '/servizi',
      name: 'servizi',
      component: Servizi
    },
    {
      path: '/contatti',
      name: 'contatti',
      component: Contatti
    },
    {
      path: '*',
      name: 'not-found',
      component: NotFound
    }
  ]
})

Vue.use(VueAnalytics, {
  id: 'UA-121230394-1',
  beforeFirstHit () {
    Vue.$ga.set('anonymizeIp', true)
  },
  router
})

export default router