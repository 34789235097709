<template>
  <main>
    <Navigation />
    <div class="lavoro">
      <header class="lavoro__header" :class="[$route.params.lavoro == 'oggetti-design' ? 'lavoro__header--small-margin' : '']">
        <h1 v-html="lavoro.titoloHtml" />
      </header>

      <div class="lavoro__content" v-show="$route.params.lavoro !== 'oggetti-design'">
        <figure :class="'image--intro'">
          <picture>
            <source
              :data-srcset="lavoro.coverIntroSmall"
              media="--small" />
            <source
              :data-srcset="lavoro.coverIntroLarge"
              media="--large" />
            <img
                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                :data-src="lavoro.coverIntro"
                :alt="lavoro.titolo"
                class="lazyload"
                data-sizes="auto" />
          </picture>
        </figure>
      </div>

      <ul v-show="lavoro.anno !== undefined || lavoro.luogo !== undefined ||  lavoro.cliente !== undefined"  class="lavoro__info">
        <li v-show="lavoro.anno !== undefined">
          <h2>Anno</h2>
          <p>{{lavoro.anno}}</p>
        </li>
        <li v-show="lavoro.luogo !== undefined">
          <h2>Luogo</h2>
          <p>{{lavoro.luogo}}</p>
        </li>
        <li v-show="lavoro.cliente !== undefined">
          <h2>Cliente</h2>
          <p>{{lavoro.cliente}}</p>
        </li>
      </ul>

      <TwoColText v-show="lavoro.descrizione !== undefined" :text="lavoro.descrizione" />    

      <BackButton :isVisible="this.listInView" />

      <div v-show="$route.params.lavoro !== 'oggetti-design'"  class="lavoro__content__anchor" v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }" />

      <div class="lavoro__content">

        <figure 
          v-for="(image, index) in lavoro.gallery" :key="index">
          
          <div v-show="$route.params.lavoro === 'oggetti-design' && index == 2"  class="lavoro__content__anchor" v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }" />
          
          <revealBlock>

            <figcaption v-show="image.titoloImmagine !== undefined" class="lavoro__content__header">
              <h2 v-show="image.titoloImmagine !== undefined" v-html="image.titoloImmagine" />
              <p v-show="image.descriptionImmagine !== undefined" v-html="image.descriptionImmagine" />
            </figcaption>

            <picture :class="[image.hasborder === true ? 'bordered' : '']">
              <source
                :data-srcset="image.immagineSmall"
                media="--small" />
              <source
                :data-srcset="image.immagineLarge"
                media="--large" />
              <img
                  src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                  :data-src="image.immagine"
                  :alt="lavoro.titolo"
                  class="lazyload"
                  data-sizes="auto" />
            </picture>
          </revealBlock>
        </figure>
      </div>

      <div class="lavoro__content__anchor" v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }" />
     
      <div class="lavoro__content">
        <p class="lavoro__content__closure">
          <router-link :to="{ name: 'lavori' }">Tutti i lavori</router-link>
        </p>
      </div>
      <Footer />
    </div>
  </main>
</template>

<script>

import Navigation from './../../components/Navigation/Navigation.vue'
import Footer from './../../components/Footer/Footer.vue'
import TwoColText from './../../components/TwoColText/TwoColText.vue'
import RevealBlock from './../../components/RevealBlock/RevealBlock.vue'
import BackButton from './../../components/BackButton/BackButton.vue'

import _ from 'lodash'
import seoJson from './../../utils/seoJson.js'

const lavoriData = require("../Lavori/data.json")

export default {
  name: 'lavoro',
  components: {
    Navigation,
    Footer,
    TwoColText,
    RevealBlock,
    BackButton
  },
  data: () => ({
    lavori: lavoriData,
    listInView: false,
    intersectionOptions: {
      threshold: 0
    },
  }),
  computed: {
    lavoro () {
     return _.find(this.lavori, ['url', this.$route.params.lavoro])
    }
  },
  methods: {
    onWaypoint ({el, going, direction }) {

      if (going === this.$waypointMap.GOING_IN && direction === this.$waypointMap.DIRECTION_TOP) {
        this.listInView = !this.listInView 
      }
      else if(going === this.$waypointMap.GOING_OUT && direction === this.$waypointMap.DIRECTION_BOTTOM) {
        this.listInView = !this.listInView 
      }
      
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      _.find(vm.lavori, ['url', to.params.lavoro]) === undefined ? next({ name: 'not-found' }) : next()
    })
  },
  beforeRouteUpdate(to, from, next) {
    _.find(this.lavori, ['url', to.params.lavoro]) === undefined ? next({ name: 'not-found' }) : next()
  },
  metaInfo () {
    return {
      title: this.lavoro.titolo,
      meta: [
        {
          name: 'description', 
          content: this.lavoro.description 
        }
      ],
      links: [
        {rel: 'canonical', href: 'https://www.alessandromoretto.it/lavori/'+this.$route.params.lavoro}
      ]
      // meta:  seoJson(this.lavoro.titolo,'https://www.alessandromoretto.it/lavori/'+this.$route.params.lavoro,this.lavoro.description, this.lavoro.coverIntro)
    }
  }
}

</script>

<style lang="scss">

@import './style.scss';

</style>