<template>
  <main>
    <Navigation />
    <header class="lavori__header">
      <h1 v-html="'Progetti di architettura, nuove costruzioni, restauri e aggiustamenti'" />
    </header>
    <ul :class="'lavori'">
      <li v-for="(lavoro, index) in lavori" :key="index">
        <router-link 
          @mouseover.native="updateMouseCursor('inProject')" 
          @mouseout.native="updateMouseCursor('base')" 
          @click.native="updateMouseCursor('base')" 
          :to="{ name: 'lavoro', params: { lavoro: lavoro.url }}">
          <figure>
            <img
              src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
              :data-src="lavoro.cover"
              :alt="lavoro.titolo"
              class="lazyload"
              data-sizes="auto" />
          </figure>
          <h2>{{ lavoro.titolo }}</h2>
          <!--<h3>{{ lavoro.luogo }}, {{ lavoro.anno }}</h3>-->
        </router-link>
      </li>
    </ul>

    <Footer />

  </main>
</template>

<script>

import { mapMutations } from 'vuex'
import Navigation from './../../components/Navigation/Navigation.vue'
import Footer from './../../components/Footer/Footer.vue'
import seoJson from './../../utils/seoJson.js'

const lavoriData = require("./data.json")

export default {
  name: 'lavori',
  components: {
    Navigation,
    Footer
  },
  data: () => ({
    lavori: lavoriData
  }),
  props: {
  },
  methods: {
    ...mapMutations([
      'updateMouseCursor'
    ])
  },
  computed: {
  },
  metaInfo: {
    title: 'Lavori, progetti e realizzazioni',
    meta: [
      {
        name: 'description', 
        content: 'Selezione delle migliori referenze di progetti architettonici, di ristrutturazione, restauro e design.'}
    ],
    links: [
      {rel: 'canonical', href: 'https://www.alessandromoretto.it/lavori/'}
    ]
    // meta:  seoJson('Lavori, progetti e realizzazioni','https://www.alessandromoretto.it/lavori/','Selezione delle migliori referenze di progetti architettonici, di ristrutturazione, restauro e design.', '/assets/img/social/lavori-alessandro-moretto.jpg')
  }
}

</script>

<style lang="scss">

@import './style.scss';

</style>