<template>
  <main>
    <Navigation />
    <div class="notFound" >
      <MainText :text="content.intro" />    
      <Footer />
    </div>
  </main>
</template>

<script>

import Navigation from './../../components/Navigation/Navigation.vue'
import Footer from './../../components/Footer/Footer.vue'
import MainText from './../../components/MainText/MainText.vue'

export default {
  name: 'notFound',
  components: {
    Navigation,
    Footer,
    MainText,
  },
  data: () => ({
    content: require("./data.json")
  }),
  metaInfo: {
    title: 'Pagina non trovata',
    meta: [
      { name: 'description', content: 'La pagina ricercata non è presente in questo sito.'}
    ],
    links: [
      {rel: 'canonical', href: 'https://www.alessandromoretto.it/404'}
    ]
  }
}
</script>

<style lang="scss">

@import './style.scss';

</style>