<template>
  <div class="text--twoCol" v-html="text"></div>
</template>

<script>

export default {
  name: 'TwoColText',
  props: {
    text: {
      type: String
    }
  }
}

</script>

<style lang="scss">

@import './style.scss';

</style>

