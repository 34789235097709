<template>
  <main>
    <Navigation />
    <div class="contatti" >
      <header class="contatti__header">
        <h1>Contatti</h1>
      </header>
      <MainText :text="content.intro" /> 

      <div class="image--closure">
        <figure>
          <picture>
            <source
              :data-srcset="content.images[0].srcSmall"
              media="--small" />
            <source
              :data-srcset="content.images[0].srcLarge"
              media="--large" />
            <img
                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                :data-src="content.images[0].src"
                class="lazyload"
                data-sizes="auto"
                alt="content.images[0].alt" />
          </picture>
        </figure>
      </div>
        
      <Footer />
    </div>
  </main>
</template>

<script>

import Navigation from './../../components/Navigation/Navigation.vue'
import Footer from './../../components/Footer/Footer.vue'
import MainText from './../../components/MainText/MainText.vue'
import seoJson from './../../utils/seoJson.js'

export default {
  name: 'contatti',
  components: {
    Navigation,
    Footer,
    MainText,
  },
  data: () => ({
    content: require("./data.json")
  }),
  metaInfo: {
    title: 'Richiedi informazioni su progetti e preventivi',
    meta: [
      {
        name: 'description', 
        content: 'Tutti i riferimenti per contattare lo studio di architettura.'}
    ],
    links: [
      {rel: 'canonical', href: 'https://www.alessandromoretto.it/contatti'}
    ],
    // meta:  seoJson('Alessandro Moretto: informazioni su progetti e preventivi','https://www.alessandromoretto.it/','Tutti i riferimenti per contattare lo studio di architettura.', '/assets/img/social/contatti-alessandro-moretto.jpg')
  }
}
</script>

<style lang="scss">

@import './style.scss';

</style>